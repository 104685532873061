<template>
  <v-container>
    <v-row>
      <!---
        <v-col cols="12" md="12" v-if="adminSettings && (adminSettings.enableApiCreation || adminSettings.isSuperUser)">
          <v-btn dark color="primary" @click="routeToApiBuilder" :disabled="isDisabled">Create an Api</v-btn>
        </v-col> --->
       <v-row v-if="adminSettings && (adminSettings.enableApiCreation || adminSettings.isSuperUser) && isLoading == true">
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
          <v-col cols="12" md="6">
            <v-skeleton-loader
              class="pa-2"
              type="list-item-three-line"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
    </v-row>
    <v-row v-if="adminSettings && (adminSettings.enableApiCreation || adminSettings.isSuperUser) && isLoading == false">
        <v-col cols="12">
          <v-row align="center" justify="start">
              <v-card
                  class="ma-2 pa-1"
                  :class="{ noTests: (api.health === 'NoTests'), healthy: (api.health === 'Healthy'), unhealthy: (api.health === 'Unhealthy') }"
                  hover
                  outlined
                  ripple
                  v-for="(api, index) in apis" :key="index"
                  max-width="344"
                  min-width="344"
                  :color="$vuetify.theme.dark ? 'grey darken-4' : ''"
                  :to="'/apis/' + api.id"
              >
              <v-list-item>
                  <v-list-item-content>
                  <v-list-item-title class="api-name"><strong>{{ api.displayName }}</strong></v-list-item-title>
                  <v-list-item-subtitle>{{ api.version }}</v-list-item-subtitle>
                  <v-list-item-subtitle v-if="api.health === 'NoTests'"><strong>Health: </strong><v-chip small dark color="orange">No Tests</v-chip></v-list-item-subtitle>
                  <v-list-item-subtitle v-if="api.health === 'Unhealthy'"><strong>Health: </strong><v-chip small dark color="red">{{ api.health }}</v-chip></v-list-item-subtitle>
                  <v-list-item-subtitle v-if="api.health === 'Healthy'"><strong>Health: </strong><v-chip small dark color="green">{{ api.health }}</v-chip></v-list-item-subtitle>
                  </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-tooltip bottom v-if="api.health === 'Healthy'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="success" v-bind="attrs" v-on="on">mdi-shield-check</v-icon>
                    </template>
                    <span>This API's healthchecks are all healthy</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="api.health === 'Unhealthy'">
                  <template v-slot:activator="{ on, attrs }">
                      <v-icon color="error" v-bind="attrs" v-on="on">mdi-shield-alert</v-icon>
                  </template>
                  <span>This API has one or more failing healthchecks</span>
                  </v-tooltip>
                   <v-tooltip bottom v-if="api.health === 'NoTests'">
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon color="warning" v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                    </template>
                    <span>This API has no healthchecks, consider adding healthchecks to be notified of API issues</span>
                    </v-tooltip>
              </v-card-actions>
              </v-card>
          </v-row>
          <v-row>
    </v-row>
        </v-col>
        <v-col cols="12" v-if="apis.length === 0 && isLoading == false">
        No APIs found for this product.
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import marked from 'marked'
import userSearchComponent from '@/components/userSearchComponent'
import groupSearchComponent from '@/components/groupSearchComponent'

export default {
  name: 'productApis',
  computed: {
    ...mapGetters(['adminSettings'])
  },
  data: () => ({
    copyText: 'Copy to clipboard',
    productId: '',
    isLoading: false,
    userError: false,
    product: {
      displayName: '',
      description: '',
      allowedUsers: [ ],
      subscriptions: [ ]
    },
    affiliates: [ ],
    apis: [ ],
    healthCheckDone: false
  }),
  methods: {
    getUserDetails: function (username) {
      return axios(
        `${process.env.VUE_APP_BASENETWORKAPIURL}/activedirectory/search/user?searchText=${username}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            return response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getUserDetails() })
          })
    },
    getProduct: function () {
      return axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            var res = response.data
            res.appKeys.forEach(x => {
              x.showKeys = false
            })

            this.product = res
            if (this.product.allowedUsers) {
              this.allowedUsers = [ ]
              this.product.allowedUsers.forEach(x => {
                this.getUserDetails(x).then(x => {
                  if (x) {
                    this.allowedUsers.push(x[0])
                  }
                })
              })
            }

            if (this.product.allowedGroups) {
              this.allowedGroups = this.product.allowedGroups
            }
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getProduct() })
          })
    },
    getApis: async function () {
      await axios(
        `${process.env.VUE_APP_BASEURL}/product/${this.productId}/apis`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          async (response) => {
            this.apis = response.data
            for (let index = 0; index < this.apis.length; index++) {
              const element = this.apis[index]
              // await this.getApiHealthAtAGlance(element)
            }
            this.healthCheckDone = true
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    },
    getApiHealthAtAGlance: async function (api) {
      await axios(
        `${process.env.VUE_APP_BASEURL}/tests/api/${api.id}/glance`,
        {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })
        .then(
          (response) => {
            api.health = response.data
          },
          (error) => {
            this.$store.commit('showErrorDialog', { errorData: error, persistErrorDialog: false, retryMethod: () => this.getApis() })
          })
    },
    routeToApiBuilder: function () {
      this.$router.push(`/products/${this.productId}/builder`)
    },
    routeToApi: function (id) {
      this.$router.push(`/apis/${id}`)
    },
    routeToApiPublicView: function (id) {
      this.$router.push(`/api-view/${id}`)
    }
  },
  mounted: async function () {
    this.isLoading = true
    if (this.$route.params.id) {
      this.productId = this.$route.params.id
      await this.getProduct().then(() => {
        this.$store.commit('breadcrumbs', [
          {
            text: 'My Products',
            exact: true,
            disabled: false,
            to: '/products'
          },
          {
            text: this.product.displayName,
            exact: true,
            disabled: false,
            to: `/products/${this.productId}`
          },
          {
            text: 'Apis',
            exact: true,
            disabled: false,
            to: `/products/${this.productId}/apis`
          }
        ])
      })
      await this.getApis()
      this.isLoading = false
    } else {
      alert('could not find product as an ID was not provided in the url')
    }
  }
}
</script>

<style scoped>
.user-table {
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.key-table {
  /* border: 1px solid rgba(0, 0, 0, 0.4);
  padding: 5px; */
  margin: 2px;
  margin-bottom: 15px;
}
.user-error {
  font-size: 12px;
}

.api-name {
  margin-bottom: 10px;
}

.theme--light.noTests {
  z-index: 0;
  border-left: 8px solid #ffc845 !important;
}
.theme--dark.noTests {
  z-index: 0;
  border-left: 8px solid #ffc845 !important;
}

.theme--light.healthy {
  border-left: 8px solid #009137 !important;
}
.theme--dark.healthy {
  border-left: 8px solid #009137 !important;
}

.theme--light.unhealthy {
  border-left: 8px solid #ff0000 !important;
}
.theme--dark.unhealthy {
  border-left: 8px solid #ff0000 !important;
}

.visible {
  margin-bottom: 8px;
}

.generate-token {
  margin-top:15px;
  padding-left: 5px;
  padding-right: 5px;
  min-width: 150px !important;
}
#v-step-8 {
  max-width: 200px;
}

</style>
